// import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/icons/logo.png';

// ==============================|| LOGO ||============================== //

const Logo = () => {
    // const theme = useTheme();

    return (
        <>
            <img src={logo} alt="Berry" width="100" height="50" />
            {/* <h3 color={theme.palette.primary.main}>Vendor Store</h3> */}
        </>
    );
};

export default Logo;
